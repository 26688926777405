import Img from "gatsby-image/index"
import React from "react"

export default ({ author }) => {
  return (
    <Img
      fluid={{
        aspectRatio: 1.6,
        src: author?.avatar?.publicURL
      }}
      style={{ width: 30, height: 30, borderRadius: "50%", margin: "0 10px 0 10px" }}
    />
  )
}
