import React from "react"
import ReactMarkdown from "react-markdown"

export default ({ article }) => {
  const content = article.content
  return (
    <ReactMarkdown
      source={getContentInfo(content)}
      transformImageUri={uri => uri.startsWith("http") ? uri:`${process.env.IMAGE_BASE_URL}${uri}`}
    />
  )
}
export const getContentInfo = (content) => {
  return content.split("\n").slice(0, 3).join("\n")
}
